// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-container-performance-detail-js": () => import("./../../../src/container/performanceDetail.js" /* webpackChunkName: "component---src-container-performance-detail-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-introduction-ceo-introduce-js": () => import("./../../../src/pages/introduction/ceo-introduce.js" /* webpackChunkName: "component---src-pages-introduction-ceo-introduce-js" */),
  "component---src-pages-introduction-location-js": () => import("./../../../src/pages/introduction/location.js" /* webpackChunkName: "component---src-pages-introduction-location-js" */),
  "component---src-pages-introduction-work-history-js": () => import("./../../../src/pages/introduction/work-history.js" /* webpackChunkName: "component---src-pages-introduction-work-history-js" */),
  "component---src-pages-introduction-work-vision-js": () => import("./../../../src/pages/introduction/work-vision.js" /* webpackChunkName: "component---src-pages-introduction-work-vision-js" */),
  "component---src-pages-promotion-catalog-js": () => import("./../../../src/pages/promotion/catalog.js" /* webpackChunkName: "component---src-pages-promotion-catalog-js" */),
  "component---src-pages-promotion-link-js": () => import("./../../../src/pages/promotion/link.js" /* webpackChunkName: "component---src-pages-promotion-link-js" */),
  "component---src-pages-promotion-news-js": () => import("./../../../src/pages/promotion/news.js" /* webpackChunkName: "component---src-pages-promotion-news-js" */),
  "component---src-pages-promotion-promotion-video-js": () => import("./../../../src/pages/promotion/promotion-video.js" /* webpackChunkName: "component---src-pages-promotion-promotion-video-js" */),
  "component---src-pages-technology-patent-js": () => import("./../../../src/pages/technology/patent.js" /* webpackChunkName: "component---src-pages-technology-patent-js" */),
  "component---src-pages-technology-performance-js": () => import("./../../../src/pages/technology/performance.js" /* webpackChunkName: "component---src-pages-technology-performance-js" */)
}

